/** @jsxImportSource @emotion/react */

import useSessionState from "hooks/useSessionState";
import GlobalToastHandler from "components/GlobalToastList/GlobalToastList";
import GlobalToastList from "components/GlobalToastList/GlobalToastList";
import "@aws-amplify/ui-react/styles.css";
import "./App.scss";
import AppHeader from "components/AppHeader/AppHeader";
import { SentryErrorBoundary } from "components/SentryErrorBoundary/SentryErrorBoundary";
import { useEffect } from "react";
import { PageRoutes } from "../routes/PageRoutes";
import { NetworkError } from "components/NetworkError/NetworkError";
import { css } from "@emotion/react";
import { useIsCurrentVersion } from "pages/App/useIsCurrentVersion";
import { ModalNewVersion } from "components/ModalNewVersion/ModalNewVersion";
import { ContactSupport } from "components/ContactSupport/ContactSupport";

const styles = {
  appContainer: css`
    height: 100vh;
  `,
  flexColumn: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  `,
  bodyContainer: css`
    width: 100%;
    height: 100%;
    overflow: auto;
  `,
};

/**
 * Top-level Application container.
 */

function App() {
  const isCurrentVersionResult = useIsCurrentVersion();
  useSessionState();

  // disable default drop behavior in browser
  // we handle all drop events explicitly
  useEffect(() => {
    const onDrop = (e: DragEvent) => {
      e.preventDefault();
    };

    const onDragOver = (e: DragEvent) => {
      e.preventDefault();
    };

    window.addEventListener("drop", onDrop);
    window.addEventListener("dragover", onDragOver);

    return () => {
      window.removeEventListener("drop", onDrop);
      window.removeEventListener("dragover", onDragOver);
    };
  }, []);

  return (
    <SentryErrorBoundary tag={"app"}>
      <div css={styles.appContainer}>
        <div css={styles.flexColumn}>
          <AppHeader />
          <div css={styles.bodyContainer}>
            <PageRoutes />
          </div>
        </div>
      </div>
      <GlobalToastList />
      <GlobalToastHandler />
      <NetworkError />
      {!isCurrentVersionResult.isCurrentVersion && (
        <ModalNewVersion newVersion={isCurrentVersionResult.newVersion} />
      )}
    </SentryErrorBoundary>
  );
}

export default App;
